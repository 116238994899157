import React from "react";
import Layout from "./layout";
import EmptySpace from "./academics/EmptySpace";
import BgSubHeader from "./BgSubHeader";

const AboutUs = () => {
  const aboutSchema = `
  <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "MedicalOrganization",
  "name": "Cutis Hospital - Skin Hair Nail : Best Dermatology Hospital in Bangalore",
  "url": "https://cutis.org.in/",
  "logo": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png",
  "sameAs": [
    "https://www.facebook.com/CutisHospitals/",
    "https://www.instagram.com/cutishospitalbengaluru/",
    "https://www.linkedin.com/company/cutis-hospital-skin-hair-nail/",
    "https://www.youtube.com/c/CutisHospital"
  ]
}
</script>

  `;
  return (
    <>
      <Layout>
        <main id="main">
          <BgSubHeader title={"ABOUT US"} />
          <EmptySpace height={30} />
          <div className="container">
            <h2 className="h1-align-mobile about-us-title-mob">
              Welcome to Cutis Academy of Cutaneous Sciences.
            </h2>
            <div className="row">
              <div className="col-lg-9 order-sm-0 order-1">
                <h2 className="h1-align-mobile about-us-title-desk">
                  Welcome to Cutis Academy of Cutaneous Sciences.
                </h2>
                <p>
                  CUTIS Academy of Cutaneous Sciences (CACS) is the brainchild
                  of Dr B.S.Chandrashekar, an eminent clinician who is
                  internationally recognised in the field of Dermatology. CUTIS
                  initially started as a small clinic in the year 1997 and has
                  grown into a full-fledged super-speciality skin institute. We
                  have out-patients of more than 350+ per day.
                </p>
                <p>
                  CACS pioneers in providing high quality dermatology care and
                  takes great pride in full filling educational, research and
                  community service missions with excellence. CACS is enriched
                  by the laurels brought by the faculty members and students in
                  the form of research publications, projects, fellowships and
                  community participation.
                </p>
              </div>
              <div className="col-lg-3 order-sm-1 order-0">
                <img
                  src="/assets/img/about/hospital.jpg"
                  className="img-fluid img-fluid2 aboutUsPageImg aboutusimage"
                  alt="Cutis Hospital Exterior Photo"
                />
              </div>
            </div>

            <EmptySpace height={30} />
            {/* <hr/>
                            <EmptySpace height={30}/> */}

            <div className="row aboutusBlueBG">
              <div className="col-lg-3">
                <img
                  src="/assets/img/about/dr-about.jpg"
                  className="img-fluid aboutUsPageImg"
                  alt="Dr. B S Chandrashekar - Chief Dermatologist & Medical Director"
                />
                <h5 className="ml-4">Dr. B. S. Chandrashekar</h5>
                <h6 className="ml-3">
                  <span className="single-line">
                    Chairman and Managing Director - Cutis Groups
                  </span>
                </h6>
              </div>
              <div className="col-lg-9">
                <h5>Vision:</h5>
                <p>
                  <strong>Cutis:</strong> Leader in dermatology, dedicated to
                  crafting exceptional care for skin, hair, and nails.
                </p>
                <p>
                  <strong>CACS:</strong> Epitome of care, education and
                  research.
                </p>
                <h5>Mission:</h5>
                <p>
                  To provide patient-centric, ethical practice, delivering
                  high-quality clinical, surgical, and cosmetic care to all
                  under one roof, led by eminent dermatologists.
                </p>
                <h5>Education:</h5>
                <p>
                  CUTIS Academy of Cutaneous Sciences (CACS) is enriched by the
                  laurels brought by faculty members and students in the form of
                  research publications, projects, fellowships and community
                  participation. Currently CACS is offering fellowships in
                  Dermatosurgery, Pediatric Dermatology and Aesthetic
                  Dermatology. Other ventures that are in the pipeline include
                  fellowship in Trichology, Dermatology Nursing, Training
                  Courses for Practicing Dermatologists in various fields and
                  exchange programs for foreign students in association with
                  international dermatological centers.
                  <br /> <br /> We encourage students from other institute to
                  uplift their knowledge in fellowship training.
                </p>
              </div>
            </div>

            <EmptySpace height={30} />
            {/* <hr/>
                            <EmptySpace height={30}/> */}

            <div className="row">
              <div className="col-lg-9 ">
                <h4>Values</h4>
                <div className="">
                  <ul>
                    <li>
                      <p>
                        <b>Patients are priority:</b>
                        <br />
                        We at [CACS] are committed towards compassionate patient
                        care providing the best quality of service to our
                        patients.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Professionalism:</b>
                        <br />
                        We are committed to serving our patients with immense
                        integrity and accountability and live up to high
                        clinical and ethical standards.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Education and research:</b>
                        <br />
                        We are committed to pioneering in advanced clinical and
                        aesthetic dermatological care, academics and research.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Social responsibility:</b>
                        <br />
                        We are committed in contributing towards the public
                        health through awareness programs and camps.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 order-first-mobile">
                <img
                  src="/assets/img/about/Dr-Manjula-MN-new.jpg"
                  className="img-fluid aboutUsPageImg"
                  alt="Dr. Manjula M.N - C.E.O & Con. Gynaecologist at Cutis"
                />
                <h5 className=" ml-5 mx-5 ">Dr. Manjula C.N</h5>
                <h6 className="ml-4">C.E.O & Con. Gynaecologist</h6>
              </div>
            </div>

            <EmptySpace height={30} />
            {/* <hr/>
                            <EmptySpace height={30}/> */}

            <div className="row aboutusBlueBG">
              {/* <div className="col-lg-3">
                  <img src="" className="img-fluid aboutUsPageImg" alt="" />
                </div> */}
              <div className="col-lg">
                <h5>Community:</h5>
                <p>
                  CACS is planning to develop a project in close association
                  with the Government bodies. The new project – Helping the
                  Helpless through Teledermatology (HHTD)- has been already
                  rolled out for treating patients across country through
                  Teledermatology for which the institute has already procured
                  separate internet lease lines. CACS is also planning to start
                  “AASARE”- a clinic for low socio economic strata patients
                  where no fee will be charged for consultation and the drugs
                  will be distributed free of cost.
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center m-5">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Jl9wtkbwXYQ"
                srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/Jl9wtkbwXYQ?autoplay=1><img src=https://img.youtube.com/vi/Jl9wtkbwXYQ/hqdefault.jpg alt='Video The Dark Knight Rises: What Went Wrong? – Wisecrack Edition'><span>▶</span></a>"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="The Dark Knight Rises: What Went Wrong? – Wisecrack Edition"
              ></iframe>
            </div>
          </div>
        </main>
        <iframe srcDoc={aboutSchema} title="Blog Schema" width="0" height="0" />
      </Layout>
    </>
  );
};

export default AboutUs;
